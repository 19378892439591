import React, { useState, useRef, useEffect, useContext } from "react"
import { graphql } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import SEO from "../../components/seo"

// 🎨 UI
import IntroPageWrapper from "../../components/Contents/IntroPageWrapper"
import ProjektzieleSlider from "../../components/Slider/ProjektzieleSlider"
import { Flex, Box } from "@chakra-ui/react"

// 🚀 Transition
import TransitionButton from "../../components/Buttons/TransitionButton"
import Transition from "../../components/Animation/Transition"
import { moveCurrentPageOut } from "../../components/Animation/PageTransition"
import { moveCameraToLayer } from "../../components/Animation/CameraAnimation"
import ThemeContext from "../../components/Map/ThemeContext"
import { batchActions } from "redux-batched-actions"

export default function Projektziele({ data, location, pageContext }) {
  const sendActionToStore = useDispatch()
  const post = data.allWpProjektziel.nodes.filter(
    i =>
      i.acf_projektziel_content.groupLeft.relationMobilitaetsschwerpunkt
        ?.slug === pageContext.schwerpunktSlug
  )
  const settings = data.wpModeSettings?.acf_projektziel_settings
  const CURRENT_PATH = useSelector(state => state.currentPath)
  const PREVIOUS_PATH = useSelector(state => state.previousPath)
  const LANG = useSelector(state => state.currentLang)
  const { portal, setPortal } = useContext(ThemeContext)
  // 🚀 Transitions Settings
  const [exit, setExit] = useState(false)
  const defaultInAnimation = location?.state ? location.state.direction : "fade"
  const [AnimationDirection, setAnimationDirection] =
    useState(defaultInAnimation)

  // 🚀🧨 Transitions Redux Triggers
  const TRANSITION_INFO = useSelector(state => state.transition)
  useEffect(() => {
    if (TRANSITION_INFO.shouldExit && TRANSITION_INFO.direction) {
      setAnimationDirection(TRANSITION_INFO.direction)
      setExit(TRANSITION_INFO.shouldExit)
      sendActionToStore({
        type: `PageChangeTransition/ended`,
      })
    }
    return () => {
      setExit(true)
    }
  }, [TRANSITION_INFO.direction, TRANSITION_INFO.shouldExit, sendActionToStore])

  const [unmountSlider, setUnmountSlider] = useState(false)

  // * Send ref to store
  const portalRef = useRef()
  useEffect(() => {
    // TODO: top_visible, bottom_visible locationsActive ausschalten
    if (!unmountSlider) {
      // * Hide locations from map
      sendActionToStore(
        batchActions(
          [
            {
              type: "change/locationsActive",
              payload: false,
            },
            {
              type: `change/projectScene`,
              payload: {
                topVisible: false,
                bottomVisible: false,
              },
            },
            {
              type: "change/map/marker",
              payload: [],
            },
            // {
            //   type: "change/markerContainerPortal",
            //   payload: portalRef,
            // },
          ],
          "PROJEKTZIEL_BATCH"
        )
      )
      setPortal(portalRef)
    }
  }, [sendActionToStore, setPortal, unmountSlider])

  useEffect(() => {
    sendActionToStore({
      type: "change/translatedSchwerpunktSlug",
      payload: pageContext.translatedSchwerpunktSlug,
    })
  }, [
    pageContext.translatedSchwerpunktSlug,
    post.translated,
    sendActionToStore,
  ])

  return (
    <div>
      <Transition autoIn shouldExit={exit} direction={AnimationDirection}>
        <SEO title={settings.textHeadlineProjektzieleSlider} />
        <IntroPageWrapper isSlider rightContent={""}>
          <Flex width="100%" align="center" direction="column">
            <TransitionButton
              primary
              text={settings.textTitleButtonSchwerpunkte}
              link={
                LANG === "en"
                  ? "/en/mobility-focus"
                  : "/mobilitaetsschwerpunkte"
              }
              targetPageInAnimation="up"
              onClick={() => {
                moveCameraToLayer(sendActionToStore, "schwerpunkte")
                moveCurrentPageOut(sendActionToStore, "down")
                setUnmountSlider(true)
              }}
              hasShadow
            />
            <Box height="24px"></Box>
            <ProjektzieleSlider
              settings={settings}
              slides={post}
              unmountSlider={unmountSlider}
              sendActionToStore={sendActionToStore}
              currentPath={CURRENT_PATH}
            />
            <div ref={portalRef} id="portal" />
          </Flex>
        </IntroPageWrapper>
      </Transition>
    </div>
  )
}
export const query = graphql`
  query ProjektzieleCardQuery($lang: String!) {
    wpModeSettings(
      slug: { eq: "projektziele" }
      locale: { locale: { eq: $lang } }
    ) {
      acf_projektziel_settings {
        textHeadlineProjektzieleSlider
        textButtonProjektzieleSlider
        textTitleButtonSchwerpunkte
      }
    }
    allWpProjektziel(filter: { locale: { locale: { eq: $lang } } }) {
      nodes {
        title
        uri
        slug
        translated {
          slug
        }
        acf_projektziel_card {
          textSummary
        }
        acf_projektziel_content {
          groupLeft {
            imageFeaturedImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 75) {
                    src
                    srcSet
                  }
                }
                publicURL
              }
              altText
            }
            relationMobilitaetsschwerpunkt {
              ... on WpSchwerpunkt {
                slug
                title
              }
            }
          }
        }
        acf_scene {
          location {
            acf_location {
              location {
                x
                y
              }
            }
          }
        }
      }
    }
  }
`
